import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import ExternalLink from './ExternalLink'
import ReceiptIcon from '@material-ui/icons/Receipt'

import Button from '@material-ui/core/Button'

class WarrantyIntakeTable extends Component {

  state = {
    brandName: undefined,
    sort: [],                   // sort order of the above product ids
    dateSortDir: 'desc',
    skuSortDir: 'desc',
    sortCol: 'date'
  }
  // When props change set sort state to sort descending by date by default
  static getDerivedStateFromProps(nextProps, prevState) {
    let sort = nextProps.products.map((_product, index) => index)
    // Check if props correlate with a new brandName
    if (nextProps.brandName == prevState.brandName) {
      return prevState
    }
    // If displaying a new brand sort the products initially by date
    sort.sort((a, b) => {
      let dateA = new Date(nextProps.products[a].enteredOn)
      let dateB = new Date(nextProps.products[b].enteredOn)
      return dateB - dateA
    })
    return {
      sort: sort, brandName: nextProps.brandName,
      sortCol: 'date', dateSortDir: 'desc'
    }
  }

  sortBySKU = (sort, dir) => {
    let newSort = sort
    newSort.sort((a, b) => {
      let skuA = this.props.products[a].sku
      let skuB = this.props.products[b].sku
      let comp = dir == 'asc' ? [skuA, skuB] : [skuB, skuA]
      if (comp[0] < comp[1])
        return -1
      else if (comp[0] > comp[1])
        return 1
      else
        return 0
    })
    return newSort
  }

  sortByDate = (sort, dir) => {
    let newSort = sort
    newSort.sort((a, b) => {
      let dateA = new Date(this.props.products[a].enteredOn)
      let dateB = new Date(this.props.products[b].enteredOn)
      if (dir == 'asc') {
        return dateA - dateB
      }
      else {
        return dateB - dateA
      }
    })
    return newSort
  }

  handleSortSKU = (_event) => {
    let sortDir
    if (this.state.sortCol == 'sku') {
      sortDir = this.state.skuSortDir == 'asc' ? 'desc' : 'asc'
    } else {
      sortDir = this.state.skuSortDir
    }
    let newSort = this.sortBySKU(this.state.sort, sortDir)
    this.setState({
      sort: newSort,
      skuSortDir: sortDir,
      sortCol: 'sku'
    })
  }

  handleSortDate = (_event) => {
    let sortDir
    if (this.state.sortCol == 'date') {
      sortDir = this.state.dateSortDir == 'asc' ? 'desc' : 'asc'
    } else {
      sortDir = this.state.dateSortDir
    }
    let newSort = this.sortByDate(this.state.sort, sortDir)
    this.setState({
      sort: newSort,
      dateSortDir: sortDir,
      sortCol: 'date'
    })
  }

  render() {
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>Select</TableCell>
              <TableCell>
                <TableSortLabel
                  direction={this.state.skuSortDir}
                  active={this.state.sortCol == 'sku'}
                  onClick={this.handleSortSKU}>
                  SKU
                </TableSortLabel>
              </TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Name</TableCell>
              <TableCell style={{ minWidth: 20 }}>
                <TableSortLabel
                  direction={this.state.dateSortDir}
                  active={this.state.sortCol == 'date'}
                  onClick={this.handleSortDate}
                >
                  Date Entered
                </TableSortLabel>
              </TableCell>
              <TableCell>Customer Note</TableCell>
              <TableCell>Internal Note</TableCell>
              <TableCell>Sales Credit<br />(Receipt)</TableCell>
              <TableCell>Serial#</TableCell>
              <TableCell>Created By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.sort.map((index) => {
              const product = this.props.products[index]
              let prodRow = (
                index, sku, productId, bpName, qty, date, cusNote, intNote,
                salesCreditId, salesOrderId, serialNumber, createdBy, select,
                productQty
              ) => {
                const bpUrl = 'https://use1.brightpearlapp.com'
                const bpSCLink = `${bpUrl}/patt-op.php?scode=invoice&` +
                  `oID=${salesCreditId}`
                const bpAuditLink = `${bpUrl}/p.php?numResults=500&p=report&` +
                  `report=product-audittrail&report_zone=products&report-type=` +
                  `product-audittrail&report_submit=1&productId=${productId}`
                const bpRecieptLink = `${bpUrl}/template_print.php?` +
                  `return-to-oid=${salesOrderId}&template_id=41` +
                  `&oID=${salesOrderId}&note_id=` +
                  `&return-to-oid=${salesOrderId}&contacts_id=3079#`
                return (
                  <TableRow key={index}>
                    <TableCell padding='checkbox'>
                      <Checkbox
                        checked={select}
                        onChange={(e) => this.props.onSelect(index, e)}
                      />
                    </TableCell>
                    <TableCell style={{ minWidth: 80 }}>
                      <ExternalLink
                        href={bpAuditLink}
                        content={sku}
                      />
                    </TableCell>
                    <TableCell align='center'>{qty}/{productQty}</TableCell>
                    <TableCell style={{ maxWidth: 250 }}>{bpName}</TableCell>
                    <TableCell>{date}</TableCell>
                    <TableCell style={{ maxWidth: 350 }}>{cusNote}</TableCell>
                    <TableCell style={{ maxWidth: 350 }}>
                      <TextareaAutosize
                        style={{
                          maxWidth: 250,
                          minWidth: 250,
                          width: 250,
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          fontWeight: 400,
                          fontSize: 14
                        }}
                        onChange={(e) => this.props.onChangeNote(index, e)}
                        placeholder="internal note"
                        value={intNote || ""}
                      />
                    </TableCell>
                    <TableCell style={{ minWidth: 80, textAlign: "center" }}>
                      {salesOrderId &&
                        <Button>
                          <ExternalLink
                            href={bpRecieptLink}
                            content={<ReceiptIcon />}
                          />
                        </Button>}
                      {salesCreditId &&
                        <Button>
                          <ExternalLink
                            href={bpSCLink}
                            content={salesCreditId}
                          />
                        </Button>}
                    </TableCell>
                    <TableCell>{serialNumber}</TableCell>
                    <TableCell>{createdBy}</TableCell>
                  </TableRow>
                )
              }
              let date = new Date(product.enteredOn)
              let slashDate = Intl.DateTimeFormat('en-US',
                { day: "2-digit", month: "2-digit", year: "2-digit" }
              ).format(date)
              return prodRow(
                index, product.sku, product.bpProductId, product.bpName,
                product.quantity, slashDate, product.customerNote,
                product.internalNote, product.salesCreditId,
                product.salesOrderId, product.serialNumber, product.createdBy,
                product.select, product.productQuantity
              )
            })}
          </TableBody>
        </Table>
      </div>
    )
  }
}
export default WarrantyIntakeTable
